import { template as template_073f768b47784ef6a5bc8884050b538c } from "@ember/template-compiler";
const FKControlMenuContainer = template_073f768b47784ef6a5bc8884050b538c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
