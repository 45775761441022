import { template as template_8fa2d98cf24240d4b68e468a48ee5a1a } from "@ember/template-compiler";
const FKLabel = template_8fa2d98cf24240d4b68e468a48ee5a1a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
