import { template as template_2947ef3a877845aa9ce7f673a6f19c10 } from "@ember/template-compiler";
const SidebarSectionMessage = template_2947ef3a877845aa9ce7f673a6f19c10(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
